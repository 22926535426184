export enum EEX_PATHS {
  DISCORD = "https://discord.gg/atrium",
  LEGAL = "https://www.atrium.io/",
  LEARN_MORE_WALLETS = "https://docs.cardano.org/new-to-cardano/types-of-wallets",
  DISCLAIMER = "https://discord.gg/atrium",
  PRIVACY = "https://www.atrium.io/",
  TERMS = "https://www.atrium.io/",
  TWITTER = "https://twitter.com/atrium_lab",
  MEDIUM = "https://medium.com/atrium-lab",
  FAQ = "https://medium.com/atrium-lab/diffusion-spo-vote-47cf5e1e8e3e",
}
